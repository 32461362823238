<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <btn-voltar to="/naturezas-ocorrencia" />
      </v-col>
    </v-row>

    <situacoes-ocorrencia-campos
      :carregando-salvar="carregandoSalvar"
      :carregando-buscar="carregandoBuscar"
      :validacao-formulario="validacaoFormulario"
      :natureza-ocorrencia="naturezaOcorrencia"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import naturezasOcorrencia from '@/api/naturezas-ocorrencia'

  export default {
    name: 'NaturezasOcorrenciaEditar',

    components: {
      SituacoesOcorrenciaCampos: () => import('./NaturezasOcorrenciaCampos'),
    },

    data () {
      return {
        carregandoSalvar: false,
        carregandoBuscar: false,
        validacaoFormulario: {},
        naturezaOcorrencia: {},
      }
    },

    mounted () {
      this.buscar()
    },

    methods: {
      async buscar () {
        try {
          this.carregandoBuscar = true

          const resposta = await naturezasOcorrencia.buscar(this.$route.params.id)

          this.naturezaOcorrencia = resposta.data
        } catch (e) {
          this.$router.push('/naturezas-ocorrencia', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
        } finally {
          this.carregandoBuscar = false
        }
      },

      async salvar (natureza) {
        this.carregandoSalvar = true

        try {
          await naturezasOcorrencia.atualizar(natureza.id, natureza)

          this.carregandoSalvar = false

          this.$router.push('/naturezas-ocorrencia', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_editar') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoSalvar = false
        }
      },
    },

  }
</script>
